export default class GoogleSignIn {
  constructor(config) {
    this.client_id = '793751807604-5km7paou4e1lat3vqnkq9bf9cr0shmr5.apps.googleusercontent.com';
    this.url = 'https://apis.google.com/js/client:plus.js?onload=gpAsyncInit';
    this.redirect_url = config.redirect_url;
    this.userType = config.userType;
  }

  init() {
    this._getGoogleJS();
  }

  _getGoogleJS() {
    return $.ajax({
      cache: true,
      dataType: 'script',
      url: this.url
    });
  }

  authorize() {
    gapi.auth.authorize({
      cookie_policy: 'single_host_origin',
      client_id: this.client_id,
      immediate: false,
      response_type: 'code',
      scope: 'email profile'
    }, response => this._sendResponseToServer(response));
  }

  static _displayError() {
    $('#content_container')
      .prepend('<div id="flash" class="alert"><p>Something went wrong with Google sign in<i class="fa fa-times pos_fr cursor_point" aria-hidden="true"></i></p></div>');
  }

  _redirect() {
    if (this.redirect_url != null) {
      window.location.replace(`${this.redirect_url}?google_ssi&user_type=${this.userType}`);
    }
    else {
      window.location.reload();
    }
  }

  _sendResponseToServer(response) {
    if (response && !response.error) {
      // trying to make an ajax call using the response variable breaks google sign in on edge
      // this work around recreates the response object so that google sign in works on edge
      const data = {
        code: response.code,
        cookie_policy: response.cookie_policy,
        response_type: response.response_type,
        scope: response.scope,
        session_state: response.session_state,
        status: response.status,
      };

      // google authentication succeed, now post data to server.
      jQuery.ajax({
        type: 'POST',
        url: '/auth/google_oauth2/callback',
        data: data,
        success: () => this._redirect(),
        error: () => GoogleSignIn._displayError()
      });
    }
    else {
      // google authentication failed
    }
  }
}
