export default class SignInButton {
  constructor(config) {
    this.$button = $(config.button);
    this.$buttonImg = $(this.$button).children('img');
  }

  init() {
    this._setupListeners();
  }

  _setupListeners() {
    // setup the listeners
    this._setSrc();
    this._setUpHoverListener();
  }

  _setUpHoverListener() {
    // change the button styling on hover
    this.$button.on(
      'mouseover',
      e => this._setHoverSrc(e)
    ).on(
      'mouseout',
      e => this._setSrc(e)
    );
  }

  _setSrc() {
    const src = this.$buttonImg.data('src');
    this.$buttonImg.attr('src', src);
  }

  _setHoverSrc() {
    const src = this.$buttonImg.data('hover-src');
    this.$buttonImg.attr('src', src);
  }
}
