export default class GoogleSwitchAccountLink {
  constructor(config) {
    this.$link = config.$link;
    this.googleSignIn = config.googleSignIn;
  }

  init() {
    this._setupListeners();
  }

  _setupListeners() {
    this._attachSignIn();
  }

  _attachSignIn() {
    this.$link.click((e) => {
      e.preventDefault();
      this.googleSignIn.authorize();
    });
  }
}
