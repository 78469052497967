import GoogleSignIn from 'GoogleSignIn';
import GoogleSignInButton from 'GoogleSignInButton';
import GoogleSwitchAccountLink from 'GoogleSwitchAccountLink';
import SignInButton from 'SignInButton';

const $googleButton = $('.google-sign-in-button');
const $googleLink = $('.google-switch-account');

const googleSignInConfig = {
  redirect_url: $googleButton.data('redirect-url'),
  userType: $googleButton.data('user-type')
};
const googleSignIn = new GoogleSignIn(googleSignInConfig);
googleSignIn.init();

const googleSignInButtonConfig = {
  button: $googleButton,
  googleSignIn: googleSignIn
};

const googleSignInButton = new GoogleSignInButton(googleSignInButtonConfig);
googleSignInButton.init();

const signInButtons = $('.sign-in-button');

signInButtons.each((index, button) => {
  const buttonConfig = {
    button: button
  };

  const signInButton = new SignInButton(buttonConfig);
  signInButton.init();
});

const googleSwitchAccountLinkConfig = {
  $link: $googleLink,
  googleSignIn: googleSignIn
};
const googleSwitchAccountLink = new GoogleSwitchAccountLink(googleSwitchAccountLinkConfig);
googleSwitchAccountLink.init();

window.gpAsyncInit = function() {
  gapi.auth.authorize({
    immediate: true,
    response_type: 'code',
    cookie_policy: 'single_host_origin',
    client_id: '793751807604-5km7paou4e1lat3vqnkq9bf9cr0shmr5.apps.googleusercontent.com',
    scope: 'email profile'
  }, () => {});
};
