import SignInButton from './SignInButton';

export default class GoogleSignInButton extends SignInButton {
  constructor(config) {
    // pass things required for google sign in
    super(config);
    this.googleSignIn = config.googleSignIn;
  }

  init() {
    this._setupListeners();
  }

  _setupListeners() {
    // setup the listeners
    this._attachSignIn();
    super._setupListeners();
  }

  _attachSignIn() {
    this.$button.click((e) => {
      e.preventDefault();
      this.googleSignIn.authorize();
    });
  }
}
